/* ==========================================================================
   #SPACING
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 */

/* stylelint-disable string-quotes */

$inuit-spacing-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
) !default;

$inuit-spacing-properties: (
  'padding': 'padding',
  'margin': 'margin',
) !default;

$inuit-spacing-sizes: (
  null: $inuit-global-spacing-unit,
  '-tiny': $inuit-global-spacing-unit-tiny,
  '-small': $inuit-global-spacing-unit-small,
  '-large': $inuit-global-spacing-unit-large,
  '-huge': $inuit-global-spacing-unit-huge,
  '-none': 0
) !default;


@each $property, $property-namespace in $inuit-spacing-properties {

  @each $direction, $direction-namespace in $inuit-spacing-directions {

    @each $size, $value in $inuit-spacing-sizes {

      .u-#{$property-namespace}#{$direction-namespace}#{$size} {
        #{$property}#{$direction}: $value !important;
      }

    }

  }

}

/* stylelint-enable string-quotes */
